var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"colelTable"},[_c('colel-card',{attrs:{"title":!_vm.isRecommendations
          ? _vm.$t('colelTable.title')
          : _vm.$t('colelTable.titleRecommendations'),"isLoading":_vm.isLoading,"icon":'mdi-account-supervisor-circle'},scopedSlots:_vm._u([{key:"extra-header",fn:function(){return [(!_vm.isRecommendations)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""},on:{"click":_vm.addColel}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus-circle")])],1),_c('Calendar',{staticStyle:{"width":"20%"},attrs:{"view":"month","dateFormat":"mm/yy","yearNavigator":true},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}})]}}],null,false,4122608435)},[_c('span',[_vm._v(_vm._s(_vm.$t("colelTable.add")))])]):_vm._e()]},proxy:true},{key:"content",fn:function(){return [_c('colel-table2',{key:_vm.componentKey,attrs:{"expandedRows":_vm.expandedRows,"value":_vm.data,"tableId":'colel-data-state',"filters":_vm.filters,"loading":_vm.isLoading,"loadingTitle":_vm.$t("colelTable.loading"),"rowClass":_vm.rowClass,"rows":_vm.colelActiveLength},on:{"row-click":_vm.rowClick},scopedSlots:_vm._u([{key:"tableContent",fn:function(){return [(_vm.screenSize !== 'xs')?_c('ColumnGroup',{attrs:{"type":"header"}},[_c('Row',[_c('Column',{attrs:{"header":"כתובת","colspan":2}}),_c('Column',{attrs:{"header":"מנהל","colspan":3}}),_c('Column',{attrs:{"header":"סטטוס","colspan":3}})],1),_c('Row',[_vm._l((_vm.headersToShow),function(header,index){return _c('Column',{key:index,attrs:{"header":_vm.$t(("colelTable." + header)),"sortable":true,"field":header}})}),_c('Column',{key:"last_login",attrs:{"header":_vm.$t("colelTable.last_login"),"sortable":true,"field":"colel.last_login"}}),_c('Column',{key:"attendance_status",attrs:{"header":_vm.$t("colelTable.attendance_status"),"sortable":true,"field":"attendance_status"}}),_c('Column',{key:"is_manager_lock",attrs:{"header":_vm.$t("colelTable.report_lock"),"sortable":true,"field":"is_manager_lock"}})],2),_c('Row',[_vm._l((_vm.headersToShow),function(header,index){return _c('Column',{key:index,attrs:{"field":header},scopedSlots:_vm._u([{key:"filter",fn:function(){return [_c('InputText',{staticClass:"p-column-filter",attrs:{"filter":"","placeholder":_vm.$t('colelTable.filterBy', {
                          name: _vm.$t(("colelTable." + header)),
                        })},model:{value:(_vm.filters[header]),callback:function ($$v) {_vm.$set(_vm.filters, header, $$v)},expression:"filters[header]"}})]},proxy:true}],null,true)})}),_c('Column',{key:"last_login",attrs:{"field":"last_login"},scopedSlots:_vm._u([{key:"filter",fn:function(){return [_c('InputText',{staticClass:"p-column-filter",attrs:{"filter":"","placeholder":_vm.$t('colelTable.filterBy', {
                                name: _vm.$t("colelTable.last_login"),
                              })},model:{value:(_vm.filters['last_login']),callback:function ($$v) {_vm.$set(_vm.filters, 'last_login', $$v)},expression:"filters['last_login']"}})]},proxy:true}],null,false,216820121)}),_c('Column',{key:"attendance_status",attrs:{"field":"attendance_status"},scopedSlots:_vm._u([{key:"filter",fn:function(){return [_c('InputText',{staticClass:"p-column-filter",attrs:{"filter":"","placeholder":_vm.$t('colelTable.filterBy', {
                                name: _vm.$t("colelTable.attendance_status"),
                              })},model:{value:(_vm.filters['attendance_status']),callback:function ($$v) {_vm.$set(_vm.filters, 'attendance_status', $$v)},expression:"filters['attendance_status']"}})]},proxy:true}],null,false,2452142745)}),_c('Column',{key:"is_manager_lock",attrs:{"field":"is_manager_lock"},scopedSlots:_vm._u([{key:"filter",fn:function(){return [_c('InputText',{staticClass:"p-column-filter",attrs:{"filter":"","placeholder":_vm.$t('colelTable.filterBy', {
                          name: _vm.$t("colelTable.report_lock"),
                        })},model:{value:(_vm.filters['is_manager_lock']),callback:function ($$v) {_vm.$set(_vm.filters, 'is_manager_lock', $$v)},expression:"filters['is_manager_lock']"}})]},proxy:true}],null,false,125327361)})],2)],1):_vm._e(),_vm._l((_vm.headersToShow),function(header){return _c('Column',{key:header,attrs:{"field":header,"header":_vm.$t(("colelTable." + header)),"headerStyle":"text-align: rtl","bodyStyle":_vm.bodyStyle(header),"sortable":"","filterField":header,"filterMatchMode":"contains","sortField":header},scopedSlots:_vm._u([{key:"filter",fn:function(){return [_c('InputText',{staticClass:"p-column-filter",attrs:{"filter":"","placeholder":_vm.$t('colelTable.filterBy', {
                            name: _vm.$t(("colelTable." + header)),
                          })},model:{value:(_vm.filters[header]),callback:function ($$v) {_vm.$set(_vm.filters, header, $$v)},expression:"filters[header]"}})]},proxy:true}],null,true)})}),_c('Column',{key:"last_login",attrs:{"field":"colel.last_login","header":_vm.$t("colelTable.last_login"),"headerStyle":"text-align: rtl","bodyStyle":"text-align: center; width: 50px","sortable":"","filterField":"colel.last_login","filterMatchMode":"contains","sortField":"last_login"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('div',{class:_vm.lastLoginColorClass(slotProps.data.colel.last_login)},[_vm._v(_vm._s(_vm.datesDiff(slotProps.data.colel.last_login))+" ")])]}},{key:"filter",fn:function(){return [_c('InputText',{staticClass:"p-column-filter",attrs:{"filter":"","placeholder":_vm.$t('colelTable.filterBy', {
                            name: _vm.$t("colelTable.last_login"),
                          })},model:{value:(_vm.filters['last_login']),callback:function ($$v) {_vm.$set(_vm.filters, 'last_login', $$v)},expression:"filters['last_login']"}})]},proxy:true}])}),_c('Column',{key:"attendance_status",attrs:{"field":"attendance_status","header":_vm.$t("colelTable.attendance_status"),"headerStyle":"text-align: rtl","bodyStyle":"text-align: center; width: 80px","sortable":"","filterField":"attendance_status","filterMatchMode":"contains","sortField":"attendance_status"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('div',{class:_vm.statusClass(slotProps.data.attendance_status)},[_vm._v(" "+_vm._s(_vm.statusDisplay(slotProps.data.attendance_status))+" ")])]}},{key:"filter",fn:function(){return [_c('InputText',{staticClass:"p-column-filter",attrs:{"filter":"","placeholder":_vm.$t('colelTable.filterBy', {
                            name: _vm.$t("colelTable.attendance_status"),
                          })},model:{value:(_vm.filters['attendance_status']),callback:function ($$v) {_vm.$set(_vm.filters, 'attendance_status', $$v)},expression:"filters['attendance_status']"}})]},proxy:true}])}),_c('Column',{key:"is_manager_lock",attrs:{"field":"is_manager_lock","header":_vm.$t("colelTable.report_lock"),"headerStyle":"text-align: rtl","body-style":"text-align: center; width: 80px","bodyStyle":"text-align: center; width: 80px","sortable":"","filterField":"is_manager_lock","filterMatchMode":"contains","sortField":"is_manager_lock"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('div',{class:_vm.reportLockClass(slotProps.data.is_manager_lock),on:{"click":function($event){return _vm.changeManagerLock($event, slotProps)}}},[_c('i',{class:_vm.typeIcon(slotProps.data.is_manager_lock)})])]}},{key:"filter",fn:function(){return [_c('InputText',{staticClass:"p-column-filter",attrs:{"filter":"","placeholder":_vm.$t('colelTable.filterBy', {
                            name: _vm.$t("colelTable.report_lock"),
                          })},model:{value:(_vm.filters['is_manager_lock']),callback:function ($$v) {_vm.$set(_vm.filters, 'is_manager_lock', $$v)},expression:"filters['is_manager_lock']"}})]},proxy:true}])}),(!_vm.isRecommendations)?_c('Column',{attrs:{"header":"","headerStyle":"width:1em","bodyStyle":"width:1em"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":!_vm.isDeleted(slotProps.data),"color":"secondary","icon":""},on:{"click":function($event){return _vm.showDialog($event, slotProps, 1)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-store-clock")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("colelTable.hoursButton")))])])]}}],null,false,61318272)}):_vm._e(),(!_vm.isRecommendations)?_c('Column',{attrs:{"header":"","headerStyle":"width:1em","bodyStyle":"width:1em"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary","icon":"","disabled":!_vm.isDeleted(slotProps.data)},on:{"click":function($event){return _vm.showDialog($event, slotProps, 2)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-message-reply-text")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("colelTable.messageButton")))])])]}}],null,false,1795915909)}):_vm._e(),(!_vm.isRecommendations)?_c('Column',{attrs:{"header":"","headerStyle":"width:1em","bodyStyle":"width:50px"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary","icon":"","disabled":!_vm.isDeleted(slotProps.data)},on:{"click":function($event){return _vm.showDialog($event, slotProps, 3)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cog")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("colelTable.settings")))])])]}}],null,false,4253696531)}):_vm._e(),(_vm.canDeletePerson && !_vm.isRecommendations)?_c('Column',{attrs:{"header":"","headerStyle":"width:1em","bodyStyle":"width:50px"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [(slotProps.data.colel.end_date === null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.askDeleteColel($event, slotProps)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("colelTable.delete")))])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","icon":""},on:{"click":function($event){return _vm.restoreColel($event, slotProps)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-backup-restore")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("colelTable.restore")))])])]}}],null,false,1977830956)}):_vm._e(),(_vm.isRecommendations)?_c('Column',{attrs:{"headerStyle":"width:1em","bodyStyle":"width:50px"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.rejectPerson($event, slotProps)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close-box")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("colelTable.reject")))])])]}}],null,false,1166708101)}):_vm._e(),(_vm.isRecommendations)?_c('Column',{attrs:{"headerStyle":"width:1em","bodyStyle":"width:50px"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","icon":""},on:{"click":function($event){return _vm.acceptPerson($event, slotProps)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-check-decagram")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("colelTable.accept")))])])]}}],null,false,1410998785)}):_vm._e()]},proxy:true}])})]},proxy:true}])}),_c('v-dialog',{attrs:{"max-width":"600"},on:{"click:outside":_vm.closeDialog},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('colel-accordion',{ref:"accordion",attrs:{"activeIndex":_vm.activeIndex,"selectedToEdit":_vm.selectedToEdit,"dialog":_vm.dialog,"isUpdate":_vm.isUpdate},on:{"closeDialog":_vm.closeDialog}})],1),_c('colel-delete-dialog',{attrs:{"isShow":_vm.isDeleteDialog,"title":_vm.titleDeleteDialog},on:{"closeDialog":function($event){_vm.isDeleteDialog = false},"ok":_vm.deleteColel}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }