<template >
  <div class="colelTable">

    <colel-card
        :title="
        !isRecommendations
          ? $t('colelTable.title')
          : $t('colelTable.titleRecommendations')
      "
        :isLoading="isLoading"
        :icon="'mdi-account-supervisor-circle'"
    >
      <template v-slot:extra-header>
        <v-tooltip
            bottom
            v-if="!isRecommendations"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                @click="addColel"
                color="primary"
                icon
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-plus-circle</v-icon>
            </v-btn>
            <Calendar
                style="width: 20%"
                v-model="selectedDate"
                view="month"
                dateFormat="mm/yy"
                :yearNavigator="true"
            ></Calendar>
          </template>
          <span >{{ $t("colelTable.add") }}</span >
        </v-tooltip >
      </template >


      <template v-slot:content>
        <colel-table2
            :expandedRows="expandedRows"
            :key="componentKey"
            v-on:row-click="rowClick"
            :value="data"
            :tableId="'colel-data-state'"
            :filters="filters"
            :loading="isLoading"
            :loadingTitle="$t(`colelTable.loading`)"
            :rowClass="rowClass"
		  :rows="colelActiveLength"
        >
          <template v-slot:tableContent>
            <ColumnGroup
                v-if="screenSize !== 'xs'"
                type="header"
            >
              <Row>
                <Column

                    header="כתובת"
                    :colspan="2"
                />
                <Column

                    header="מנהל"
                    :colspan="3"
                />
                <Column
                    header="סטטוס"
                    :colspan="3"
                />
              </Row>
              <Row>
                <Column
                    v-for="(header, index) in headersToShow"
                    :key="index"
                    :header="$t(`colelTable.${header}`)"
                    :sortable="true"
                    :field="header"
                />
                <Column
                    key="last_login"
                    :header="$t(`colelTable.last_login`)"
                    :sortable="true"
                    field="colel.last_login"
                />
                <Column
                    key="attendance_status"
                    :header="$t(`colelTable.attendance_status`)"
                    :sortable="true"
                    field="attendance_status"
                />
                <Column
                    key="is_manager_lock"
                    :header="$t(`colelTable.report_lock`)"
                    :sortable="true"
                    field="is_manager_lock"
                />
              </Row>
              <Row >
                <Column
                    v-for="(header, index) in headersToShow"
                    :key="index"
                    :field="header"


			 >
                  <template #filter>
                    <InputText
                        filter
                        v-model="filters[header]"
                        class="p-column-filter"
                        :placeholder="
                        $t('colelTable.filterBy', {
                          name: $t(`colelTable.${header}`),
                        })
                      "
                    />
                  </template>
                </Column>
                <Column
                    key="last_login"
                    field="last_login"
                >
                  <template #filter>
                    <InputText
                        filter
                        v-model="filters['last_login']"
                        class="p-column-filter"
                        :placeholder="
                              $t('colelTable.filterBy', {
                                name: $t(`colelTable.last_login`),
                              })
                            "
                    />
                  </template>
                </Column>
                <Column
                    key="attendance_status"
                    field="attendance_status"
                >
                  <template #filter>
                    <InputText
                        filter
                        v-model="filters['attendance_status']"
                        class="p-column-filter"
                        :placeholder="
                              $t('colelTable.filterBy', {
                                name: $t(`colelTable.attendance_status`),
                              })
                            "
                    />
                  </template>
                </Column>
                <Column
                    key="is_manager_lock"
                    field="is_manager_lock"
                >
                  <template #filter>
                    <InputText
                        filter
                        v-model="filters['is_manager_lock']"
                        class="p-column-filter"
                        :placeholder="
                        $t('colelTable.filterBy', {
                          name: $t(`colelTable.report_lock`),
                        })
                      "
                    />
                  </template>
                </Column>
              </Row>
            </ColumnGroup>
            <Column
                v-for="header in headersToShow"
                :key="header"
                :field=" header"
                :header="$t(`colelTable.${header}`)"
                headerStyle="text-align: rtl"
                :bodyStyle="bodyStyle(header)"
                sortable
                :filterField="header"
                filterMatchMode="contains"
                :sortField="header"
            >
              <template #filter>
                <InputText
                    filter
                    v-model="filters[header]"
                    class="p-column-filter"
                    :placeholder="
                          $t('colelTable.filterBy', {
                            name: $t(`colelTable.${header}`),
                          })
                        "
                />
              </template>
            </Column>
            <Column
                key="last_login"
                field="colel.last_login"
                :header="$t(`colelTable.last_login`)"
                headerStyle="text-align: rtl"
                bodyStyle="text-align: center; width: 50px"
                sortable
                filterField="colel.last_login"
                filterMatchMode="contains"
                sortField="last_login"
            >
              <template #body="slotProps">
                <div
                    :class="lastLoginColorClass(slotProps.data.colel.last_login)"
                >{{
                    datesDiff(slotProps.data.colel.last_login)
                  }}
                </div>
              </template>
              <template #filter>
                <InputText
                    filter
                    v-model="filters['last_login']"
                    class="p-column-filter"
                    :placeholder="
                          $t('colelTable.filterBy', {
                            name: $t(`colelTable.last_login`),
                          })
                        "
                />
              </template>
            </Column>
            <Column

                key="attendance_status"
                field="attendance_status"
                :header="$t(`colelTable.attendance_status`)"
                headerStyle="text-align: rtl"
                bodyStyle="text-align: center; width: 80px"

                sortable
                filterField="attendance_status"
                filterMatchMode="contains"
                sortField="attendance_status"
            >
              <template #body="slotProps">
                <div
                    :class="statusClass(slotProps.data.attendance_status)"
                > {{
                    statusDisplay(slotProps.data.attendance_status)
                  }}
                </div>
              </template>
              <template #filter>
                <InputText
                    filter
                    v-model="filters['attendance_status']"
                    class="p-column-filter"
                    :placeholder="
                          $t('colelTable.filterBy', {
                            name: $t(`colelTable.attendance_status`),
                          })
                        "
                />
              </template>
            </Column>

            <Column
                key="is_manager_lock"
                field="is_manager_lock"
                :header="$t(`colelTable.report_lock`)"
                headerStyle="text-align: rtl"
                body-style="text-align: center; width: 80px"
                bodyStyle="text-align: center; width: 80px"
                sortable
                filterField="is_manager_lock"
                filterMatchMode="contains"
                sortField="is_manager_lock"
            >
              <template #body="slotProps">
                <div
                    :class="reportLockClass(slotProps.data.is_manager_lock)"
                    @click="changeManagerLock($event, slotProps)"
                ><i
                    :class="typeIcon(slotProps.data.is_manager_lock)"
                /></div>
              </template>
              <template #filter>
                <InputText
                    filter
                    v-model="filters['is_manager_lock']"
                    class="p-column-filter"
                    :placeholder="
                          $t('colelTable.filterBy', {
                            name: $t(`colelTable.report_lock`),
                          })
                        "
                />
              </template>
            </Column>

            <Column
                v-if="!isRecommendations"
                header=""
                headerStyle="width:1em"
                bodyStyle="width:1em"
            >

              <template #body="slotProps">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        @click="showDialog($event, slotProps, 1)"
                        :disabled="!isDeleted(slotProps.data)"
                        color="secondary"
                        icon
                        v-bind="attrs"
                        v-on="on"
                    >
                      <v-icon>mdi-store-clock</v-icon>
                    </v-btn>
                  </template >
                  <span >{{ $t("colelTable.hoursButton") }}</span >
                </v-tooltip >
              </template >
            </Column >
            <Column
                v-if="!isRecommendations"
                header=""
                headerStyle="width:1em"
                bodyStyle="width:1em"
            >
              <template #body="slotProps">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        @click="showDialog($event, slotProps, 2)"
                        color="secondary"
                        icon
                        :disabled="!isDeleted(slotProps.data)"
                        v-bind="attrs"
                        v-on="on"
                    >
                      <v-icon>mdi-message-reply-text</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("colelTable.messageButton") }}</span>
                </v-tooltip>
              </template>
            </Column>
            <Column
                v-if="!isRecommendations"
                header=""
                headerStyle="width:1em"
                bodyStyle="width:50px"
            >

              <template #body="slotProps">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        @click="showDialog($event, slotProps, 3)"
                        color="secondary"
                        icon
                        :disabled="!isDeleted(slotProps.data)"
                        v-bind="attrs"
                        v-on="on"
                    >
                      <v-icon>mdi-cog</v-icon>
                    </v-btn>
                  </template >
                  <span >{{ $t("colelTable.settings") }}</span >
                </v-tooltip >
              </template >
            </Column >
            <Column
                v-if="canDeletePerson && !isRecommendations"
                header=""
                headerStyle="width:1em"
                bodyStyle="width:50px"
            >
              <template #body="slotProps">
                <v-tooltip
                    bottom
                    v-if="slotProps.data.colel.end_date === null"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        @click="askDeleteColel($event, slotProps)"
                        color="error"
                        icon
                        v-bind="attrs"
                        v-on="on"
                    >
                      <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                  </template >
                  <span >{{ $t("colelTable.delete") }}</span >
                </v-tooltip >
                <v-tooltip
                    bottom
                    v-else
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        @click="restoreColel($event, slotProps)"
                        color="success"
                        icon
                        v-bind="attrs"
                        v-on="on"
                    >
                      <v-icon>mdi-backup-restore</v-icon>
                    </v-btn>
                  </template >
                  <span >{{ $t("colelTable.restore") }}</span >
                </v-tooltip >
              </template >
            </Column >
            <Column
                v-if="isRecommendations"
                headerStyle="width:1em"
                bodyStyle="width:50px"
            >
              <template #body="slotProps">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        @click="rejectPerson($event, slotProps)"
                        color="error"
                        icon
                        v-bind="attrs"
                        v-on="on"
                    >
                      <v-icon>mdi-close-box</v-icon>
                    </v-btn>
                  </template >
                  <span >{{ $t("colelTable.reject") }}</span >
                </v-tooltip >
              </template >
            </Column >
            <Column
                v-if="isRecommendations"
                headerStyle="width:1em"
                bodyStyle="width:50px"
            >
              <template #body="slotProps">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        @click="acceptPerson($event, slotProps)"
                        color="success"
                        icon
                        v-bind="attrs"
                        v-on="on"
                    >
                      <v-icon>mdi-check-decagram</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("colelTable.accept") }}</span>
                </v-tooltip>
              </template>
            </Column>

          </template>
        </colel-table2>
      </template>


    </colel-card>


    <v-dialog
        v-model="dialog"
        max-width="600"
        v-on:click:outside="closeDialog"

    >
      <colel-accordion
          ref="accordion"
          v-on:closeDialog="closeDialog"
          :activeIndex="activeIndex"
          :selectedToEdit="selectedToEdit"
          :dialog="dialog"
          :isUpdate="isUpdate"
      ></colel-accordion>
    </v-dialog>
    <colel-delete-dialog
        v-on:closeDialog="isDeleteDialog = false"
        v-on:ok="deleteColel"
        :isShow="isDeleteDialog"
        :title="titleDeleteDialog"
    />
  </div >
</template >

<script>


import {canAddColel, canDeletePerson, canEditPerson} from "@/utils/permissions.js";
import {
  acceptColel,
  changeManagerLockStatus,
  createColel,
  deleteColel,
  getAllColel,
  getAllStatuses,
  rejectColel,
  restoreColel,
  updateColel,
} from "./colelTable";


import {mapState} from "vuex";
import i18n from "@/translate/translate";
import ColelAccordion from "@/views/colel/colelTable/colelAccordion";
import {dateToServer} from "@/utils/dateFormat";
import Vue from "vue";
import constants from "@/constants";

const curr = new Date()
const prevoius = new Date(curr.setMonth(curr.getMonth() - 1))
export default {
  name: "colelTable",
  props: {
    isRecommendations: Boolean
  },
  components: {
    ColelAccordion
  },
  data() {
    return {
      activeIndex: [0, 1, 2],
      componentKey: 0,
      expandedRows: [],
      isDeleteDialog: false,
      isMessageDialog: false,
      isHoursDialog: false,
      titleDeleteDialog: null,
      dialog: false,
      deleteProp: null,
      selectedToDelete: null,
      filters: {},
      isUpdate: null,
      expandedRowGroups: null,
      isLoading: true,
      selectedToEdit: null,
      data: [],
      messageIsNotValid: true,
      detailsIsNotValid: true,
      selectedDate: prevoius,
      selectedItemsTracing: []
    };
  },
  created() {
    this.getData()
  },
  methods: {
    bodyStyle(header){
      let style = 'text-align: right; overflow: visible;'
	 if ( header
          === 'colel.street'){
        style += ' width: 200px'

      }


	 return style
    },
    async changeManagerLock(event, slotDetails) {
      event.stopPropagation();
      try {
        const colelIndex = this.data.map(colel => colel.colel.id).indexOf(slotDetails.data.colel.id);
        const managerLockStatus = await changeManagerLockStatus(this.selectedDate, slotDetails.data.colel.id);
        this.data[colelIndex].is_manager_lock = managerLockStatus;
      } catch (e) {
        this.$toast.add({
          severity: 'error',
          summary:
              'שגיאה',
          detail:
              'לא הצלחנו לנעול את הכולל יש לנסות במועד אחר',
          life: constants.LIFE_TOAST
        });
      }
    },
    dateToServer(date) {
      return date && dateToServer(new Date(date))
    },
    lastLoginColorClass(date) {
      const diff = this.datesDiff(date)
      if (diff > 7) {
        return 'last-login-red'
      } else if (diff > 3) {
        return 'last-login-yellow'
      } else if (diff < 3) {
        return 'last-login-green'
      }
    },
    datesDiff(date) {
      const diffTime = Math.abs(new Date(date) - new Date());
      return date && Math.ceil(diffTime / (1000 * 60 * 60 * 24) - 1);
    },
    async getColels() {
      const [error, data, options] = await getAllColel()
      if (data) {
        this.isLoading = false;
        this.data = data
      } else if (error) {
        console.log(error)
      }
    },
    async getData() {
      await this.getColels()
      await this.getStatuses()
    },
    async getStatuses() {

      try {
        const {data} = await getAllStatuses(this.selectedDate)
        data.forEach((status, index) => {
          this.data.forEach((colel, index) => {
            if (colel.colel.id === status.colel_id) {
              const merged = {
                ...this.data[index],
                report_lock: status.report_lock,
                attendance_status: status.attendance_status,
                is_manager_lock: status.is_manager_lock
              }

              Vue.set(this.data, index, merged);
            }
          })
        })

      } catch (e) {
        console.log(e)
      }

    }
    ,
    isDeleted(data) {
      return data?.colel?.end_date === null
    }
    ,
    closeColelHoursDialog() {
      this.isHoursDialog = false;
      this.selectedToEdit = null;
    }
    ,
    closeColelMessageDialog() {
      this.isMessageDialog = false;
      this.selectedToEdit = null;
    }
    ,
    rowClass(data) {
      let rowClass = "";
      !this.isDeleted(data) ? (rowClass += "row-unenabled") : null;
      return rowClass;
    }
    ,
    rowClick(e) {

      if (canEditPerson(this.user) && !this.isRecommendations &&
          this.isDeleted(e.data)) {
        this.selectedToEdit = {...e.data};
        this.selectedToEdit.colel.password =
            e.data.colel_details.permissions.password
        this.activeIndex = [0]
        this.isUpdate = true
        this.dialog = true;
      }
    }
    ,
    statusClass(status) {
      switch (status) {
        case 0:
          return 'status-red'
        case 1:
          return 'status-yellow'
        case 2:
          return 'status-green'
      }
    }
    , statusDisplay(status) {
      switch (status) {
        case 0:
          return this.$t('colelTable.empty')
        case 1:
          return this.$t('colelTable.process')
        case 2:
          return this.$t('colelTable.full')
      }
    }
    ,
    reportLockClass(isLock) {
      if (isLock) {
        return 'reportLock-true'
      } else {
        return 'reportLock-false'
      }
    }
    ,
    typeIcon(isLock) {
      if (isLock) {
        return 'pi pi-lock'
      } else {
        return 'pi pi-lock-open'
      }
    }
    ,
    addColel(event) {
      console.log("Event: ", event)
      if (canAddColel() && !this.isRecommendations) {
        this.activeIndex = [0, 1, 2, 3]

        this.selectedToEdit = {};
        this.selectedToEdit.colel = {}
        this.selectedToEdit.colel.password = ""
        this.isUpdate = false
        this.dialog = true;
      }
    }
    ,
    closeDialog() {
      this.dialog = false;
      this.$refs.accordion.reset();
      this.getData();
      this.activeIndex = [0, 1, 2, 3]
      this.selectedToEdit = {};
    }
    ,
    askDeleteColel(e, props) {
      e.stopPropagation();

      this.selectedToDelete = {...props.data};
      this.isDeleteDialog = true;
      this.titleDeleteDialog = i18n.t(`colelTable.askDelete`, {
        first_name: this.selectedToDelete?.id
      });
    }
    ,
    showDialog(e, props, position) {
      this.selectedToEdit = {...props.data};
      this.activeIndex = [position]

      this.dialog = true;

      e.stopPropagation();
    }
    ,
    async createColel(e) {
      try {
        const data = await createColel(e);
        this.data.unshift(data)
      } catch (e) {
        console.log("error: ", e)

      }
    }
    ,
    async updateColel(e) {
      const {data, error, status} = await updateColel(e);
      if (data || data === "") {

        const index = this.data.findIndex(colel => colel.id === data.id)
        this.data[index] = data
        this.componentKey++
      } else if (error) {
        console.log("error: ", e)

      }
    }
    ,
    async restoreColel(e, props) {
      e.stopPropagation();
      try {
        await restoreColel(props.data.colel.id);
        this.data.find(
            colel => colel.colel.id === props.data.colel.id).colel.end_date =
            null
      } catch (e) {
        console.log("error: ", e)

      }

    }
    ,
    async deleteColel() {

      try {
        await deleteColel(this.selectedToDelete.colel.id);

        this.data.find(
            colel => colel.colel.id ===
                this.selectedToDelete.colel.id).colel.end_date =
            new Date()
      } catch (e) {
        console.log("error: ", e)

      }
    }
    ,
    rejectPerson(e, props) {
      e.stopPropagation();

      rejectColel(props.data);
    }
    ,
    acceptPerson(e, props) {
      e.stopPropagation();

      acceptColel(props.data);
    }
  },
  computed: {
    colelActiveLength(){
      return this.data?.filter(c => c?.colel?.end_date === null).length
    },
    screenSize() {
      return this.$vuetify.breakpoint.name
    },
    headersToShow() {
      if (this.$vuetify.breakpoint.name !== 'xs') {
        return headers
      } else {
        return headersMobile
      }
    },
    canDeletePerson() {
      return canDeletePerson(this.user);
    },
    ...mapState(["user"])
  },
  watch: {
    selectedDate(newValue, oldValue) {
      this.getStatuses()
    }
  }
};

const headers = [
  "colel.city",
  "colel.street",
  "colel_details.owner.first_name",
  "colel_details.owner.last_name",
  "colel_details.owner.phone_number"
];

const headersMobile = [
  "colel.city"
];

</script >

<style lang="scss">
.last-login-red {
  background: #ffb4b4;
}

.last-login-yellow {
  background: #ffddb4;
}

.last-login-green {
  background: #d7ffb4;
}

.status-red {
  background: #ffb4b4;
}

.status-yellow {
  background: #ffddb4;
}

.status-green {
  background: #d7ffb4;
}

.reportLock-false {
  background: #ffb4b4;
}

.reportLock-true {
  background: #d7ffb4;
}

.p-datatable .p-datatable-thead > tr > th {
  text-align: center !important;
}

.p-column-filter {
  width: 100%;
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
  //display: none;
}

@media screen and (max-width: 40em) {
  .p-datatable {
    //&.p-datatable-responsive-demo {
    //  .p-datatable-thead > tr > th,
    //  .p-datatable-tfoot > tr > td {
    //    //display: none !important;
    //  }
    //
    //  .p-datatable-tbody > tr > td {
    //    text-align: left;
    //    display: block;
    //    width: 100%;
    //    float: left;
    //    clear: left;
    //    border: 0 none;
    //
    //    .p-column-title {
    //      padding: 0.4rem;
    //      min-width: 30%;
    //      //display: inline-block;
    //      margin: -0.4em 1em -0.4em -0.4rem;
    //      font-weight: bold;
    //    }
    //
    //    &:last-child {
    //      border-bottom: 1px solid var(--surface-d);
    //    }
    //  }
    //}
  }
}
</style >
