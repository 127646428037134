<template >
	   	<div class="p-grid p-jc-center">
	    <colel-calendar
		    :disabledDays="[6]"
		    class="col-12 p-md-6 p-lg-3"
		    :yearNavigator="true"
		    yearRange="2000:2030"
		    :monthNavigator="true"
		    :manualInput="false"
		    :dateFormat="dateformat"
		    touchUI
		    v-model="date"
		    :showWeek="false"
	    />
	   <div
		   class="p-d-flex p-jc-center col-12 p-md-6 p-lg-3"
	   >
		    <Button
			    icon="pi pi-angle-down"
			    iconPos="right"
			    type="button"
			    class="p-button-secondary"
			    :label="$t(`presenceReport.allPersonsPresenceReport`)"
			    @click="toggle"
			    aria-haspopup="true"
			    aria-controls="overlay_menu"
		    />
                <Menu
				 id="overlay_menu"
				 ref="menu"
				 :model="buttonsAllPresence"
				 :popup="true"
			 />
	   </div >
    </div >

</template >

<script >

import {
  getPresencesValue,
  postAllPersonsPresences
} from "@/views/presence/presenceReport/presenceReport";
import {dateToFullHebrew} from "@/utils/dateFormat";
import constants from "@/constants";

export default {
  name: "postAll",
  data() {
    return {
      date: new Date(),
      buttonsAllPresence: [
        {
          label: this.$t(`presenceReport.present`),
          icon: 'pi pi-check-circle',
          command: () => this.postAll(1)
        },
        {
          label: this.$t(`presenceReport.miss`),
          icon: 'pi pi-times-circle',
          command: () => this.postAll(2)
        },
        {
          label: this.$t(`presenceReport.allowed`),
          icon: 'pi pi-ticket',
          command: () => this.postAll(3)
        },
        {
          label: this.$t(`presenceReport.holiday`),
          icon: 'pi pi-sun',
          command: () => this.postAll(4)
        },
        {
          label: this.$t(`presenceReport.empty`),
          icon: 'pi pi-circle',
          command: () => this.postAll(5)
        }
      ],
    };
  },
  computed: {
    dateformat() {
      return dateToFullHebrew(this.date);
    },
    hebrewDate() {
      return dateToFullHebrew(this.date)
    }
  },

  methods: {
    presenceForAll(type) {
      this.postAll(type)
    },
    toggle(event) {
      event.relativeAlign = true
      this.$refs.menu.toggle(event);
    },
    async postAll(type) {
      try {
        const data = await postAllPersonsPresences(
                                                   this.date, type)
        this.$toast.add({
                          severity: 'success',
                          summary:
                              this.$t('presenceReport.postAllSuccessTitle'),
                          detail:
                              this.$t('presenceReport.postAllSuccessDetail',
                                      {type: getPresencesValue(type)}),
                          life: constants.LIFE_TOAST
                        });
      } catch (e) {
        this.$toast.add({
                          severity: 'error',
                          summary: this.$t(
                              'presenceReport.postAllErrorTitle'),
                          detail:
                              this.$t('presenceReport.postAllErrorDetail'),
                          life: constants.LIFE_TOAST
                        });
      }
    }
  }
};
</script >
<style scoped>
>>> .p-calendar .p-inputtext {
    text-align: center;
}

>>> .p-menu.p-menu-overlay {
    width: auto;
}


>>> .p-menu .p-menuitem-link .p-menuitem-text {
    padding-right: 10px;
}

</style>