import axios from "axios";
import store from "@/store";
import {dateToServer} from "@/utils/dateFormat";
import constants from "@/constants";

export async function getAllPerson() {
    const colel_id = store.state?.colel?.colel?.id
    if (store.getters.isAdmin) {
        return await axios.get(`/manager/person/all`);
    } else {
        return await axios.get(`/colel/${colel_id}/persons`);
    }
}

export async function createPerson(person) {
    const result = await axios.post(`/manager/person`, person);
    return result.status === 201;
}

export async function createPersonRecommend(person) {
    const colel_id = store.state?.colel?.colel?.id
    const suggestion = {
        colel_id,
        entity_type: constants.PERSON_TYPE,
        suggestion_type: constants.SUGGESTION_TYPE_ADD,
        create_date: dateToServer(new Date()),
        new_entity: {...person, colel_id}
    };
    const result = await axios.post(`/suggestions/person`, suggestion);
    return result.status === 200;
}

export async function updatePerson(person) {
    const result = await axios.patch(`/manager/person`, person);
    return result.status === 200;
}

export async function updatePersonRecommend(person) {
    const colel_id = store.state?.colel?.colel?.id
    const suggestion = {
        colel_id,
        entity_type: constants.PERSON_TYPE,
        suggestion_type: constants.SUGGESTION_TYPE_EDIT,
        create_date: dateToServer(new Date()),
        new_entity: person
    };
    return await axios.patch(`/suggestions/person`, suggestion);
}

export async function deletePerson(personId, date) {
    const result = await axios.delete(`/manager/person?id=${personId}&date=${date}`);
    return result.status === 202;
}

export async function deletePersonRecommend(person) {
    const colel_id = store.state?.colel?.colel?.id
    const suggestion = {
        colel_id,
        entity_type: constants.PERSON_TYPE,
        suggestion_type: constants.SUGGESTION_TYPE_DELETE,
        create_date: dateToServer(new Date()),
        new_entity: person
    };
    const result = await axios.patch(`/suggestions/person`, suggestion);
    return result.status === 200;
}

export async function restorePerson(personId) {
    try {
        const {data, headers} = await axios.post(
            `/manager/person/return?id=${personId}`);
        return [null, data, {headers}];
    } catch (error) {
        console.error(error);
        return [error, error.response?.status];
    }
}

export async function acceptPerson(person) {

}

export async function rejectPerson(person) {

}
