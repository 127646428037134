<template>
 <div class="Colel">
    <div class="p-col-12 p-md-12 p-lg-12">
	      <postAll
			 class="p-p-2"
			 v-if="isAdmin"
		 ></postAll >
      <colelTable :isRecommendations="false" />
    </div>
  </div>
</template>

<script>
import colelTable from "./colelTable/colelTable.vue";
import postAll from "@/components/postAll/postAll.vue";

import {mapGetters} from "vuex";
import personTable from "@/views/person/personTable/personTable";

export default {
  name: "Colel",
  data() {
    return {
    
    };
  },
  computed: {
    ...mapGetters(["isAdmin"]),
  },

  components: {
    colelTable,
	   postAll
  },
};
</script>
